import React, { useEffect, useState } from 'react'
import ReactFlow from 'reactflow'
import { Button } from 'reactstrap'
import { useParams, useLocation } from 'react-router-dom'
import cn from 'classnames'
import BuilderErrors from '~/pages/CampaignBuilder/BottomActions/BuilderErrors'
import LaunchModal from './components/JourneyReview/LaunchModal'
import { useStore, withStore } from '~/dataStore'
import useJourneyStore from './Journey.context'
import Markers from './components/Markers'
import { ID } from '~/common.interface'
import {
  AddToSegment,
  CustomEdge,
  Delay,
  Entry,
  Exit,
  Message,
  Split,
  Redirect
} from './Nodes'
import JourneyBuilderSidebar from './JourneyBuilderSidebar'
import JourneyBuilderStatus from '~/components/Navbar/BuilderStatus'
import JourneyBuilderControls from './components/JourneyBuilderControls'
import SetupModal from './components/SetupModal'
import {
  JourneyBlockType,
  JourneyTemplate,
  NodeData,
  NodeType,
  NodeWithData
} from './Store/JourneyBuilder.interface'
import {
  fetchDefaultJourneyTemplate,
  fetchJourneyTemplate,
  sendJourney,
  requestJourneySendApproval
} from '../Connector/Journeys.connector'
import './JourneyBuilder.scss'
import { JourneyErrors, JourneyStatus } from '../Journeys.interface'
import { getChildrenByType } from '../Journey.service'
import JourneyBottomBar from './components/JourneyBottomBar'
import AddNewBlockEdge from './Nodes/AddNewBlockEdge'
import blankJourneyTemplate from '../NewJourney/blankJourney.json'
import RedirectEdge from './Nodes/RedirectEdge'
import RequestNotificationSendingApproval from '~/components/modals/RequestNotificationSendingApproval'
import { NotificationType, showNotification } from '~/utils/Notification'

const nodeTypes = {
  entry: Entry,
  delay: Delay,
  message: Message,
  addtosegment: AddToSegment,
  exit: Exit,
  split: Split,
  redirect: Redirect
}
const edgeTypes = {
  customEdge: CustomEdge,
  addNewBlockEdge: AddNewBlockEdge,
  redirect: RedirectEdge
}

const JourneyBuilder = (): React.ReactElement => {
  const [isBusy, setIsBusy] = useState(false)
  const [errorsOpen, setErrorsOpen] = useState(false)
  const {
    ui: { setShowSidebar, showModal, hideModal, isModalOpen },
    app: {
      openCurtain,
      closeCurtain,
      currentAdmin: { isDemoAdmin },
      currentApp: { id: appId }
    },
    replaceTo,
    goTo
  } = useStore()

  const store = useJourneyStore()

  const [template, setTemplate] = useState<JourneyTemplate>(
    blankJourneyTemplate as JourneyTemplate
  )

  const { journeyId, mode } = useParams<{
    appId: ID
    journeyId: ID
    mode: 'builder' | 'details'
  }>()

  const location: { state: { template?: JourneyTemplate } } = useLocation()

  const [sidebarProps, setSidebarProps] = useState<{
    isOpen: boolean
    type: NodeType
    data: NodeData
  } | null>(null)

  function showSetupModal() {
    store.entry.storeCurrentState()
    showModal('journeyBuilderSetupModal')
  }

  async function saveJourney(): Promise<void> {
    try {
      setIsBusy(true)
      const id = await store.save()
      replaceTo('journeysBuilder', { appId, journeyId: id, mode: 'builder' })
    } catch (error) {
      console.log(error)
    } finally {
      setIsBusy(false)
    }
  }

  async function launchJourney(): Promise<void> {
    try {
      setIsBusy(true)
      await sendJourney(appId, journeyId)
      goTo('journeys', { appId })
    } catch (error) {
      if ('errors' in error.body) {
        hideModal('journeyBuilderLaunchModal')
        store.setServerErrors(error.body as JourneyErrors)
        setErrorsOpen(true)
      } else {
        console.log(error)
      }
    } finally {
      setIsBusy(false)
    }
  }

  const requestSendApproval = () => {
    showModal('requestNotificationSendingApproval', {
      requestSend: async () => {
        try {
          await requestJourneySendApproval(appId, {
            journeyId
          })
          showNotification(
            'Journey successfully sent for approval.',
            NotificationType.SUCCESS
          )
          goTo('journeys', { appId })
        } catch (error) {
          console.log(error)
        }
      }
    })
  }

  function openSidebar(elem: NodeWithData, forceOpen = false): void {
    if (!elem.type) {
      return
    }

    if (!sidebarProps || forceOpen) {
      if (elem.type === NodeType.EXIT) {
        return
      }

      elem.data.block.setActive(true)
      setSidebarProps({
        isOpen: true,
        type: elem.type as NodeType,
        data: elem.data
      })
      store.resetError()
      setErrorsOpen(false)
    }
  }

  function handleNodeClick(e: any, elem: NodeWithData) {
    openSidebar(elem)
  }

  function onLaunchClick(): void {
    if (store.validate(store.entry)) {
      showModal('journeyBuilderLaunchModal')
    } else {
      setErrorsOpen(true)
    }
  }

  function onSidebarClose() {
    sidebarProps?.data.block.setActive(false)
    setSidebarProps(null)
  }

  function openFromPanel(nodeId: ID): void {
    const node = store.nodes.find((n) => n.id === nodeId)
    if (node) {
      onSidebarClose()
      openSidebar(node, true)
    }
  }

  async function fetchPersonalTemplate(id: ID) {
    try {
      const response = await fetchJourneyTemplate(appId, id)
      setTemplate(response)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error)
    }
  }

  async function fetchTemplate(id: ID) {
    try {
      const response = await fetchDefaultJourneyTemplate(id)
      setTemplate(response)
    } catch (error) {
      if (error.status === 404) {
        fetchPersonalTemplate(id)
      } else {
        // eslint-disable-next-line no-console
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (journeyId) {
      closeCurtain()
      store
        .fetch(journeyId)
        .catch((error) => {
          if (error.status >= 400 && error.status < 500) {
            goTo('journeys', { appId })
          } else {
            console.error(error)
          }
        })
        .finally(openCurtain)
    } else if (!location.state?.template) {
      goTo('journeysNew', { appId })
    } else {
      setTemplate(location.state.template)

      store.initTemplate(location.state.template)
      showModal('journeyBuilderSetupModal')
    }
  }, [])

  useEffect(() => {
    if (
      store.entry.templateId &&
      !location.state?.template &&
      template.id === 'blankJourneyTemplate'
    ) {
      fetchTemplate(store.entry.templateId)
    }
  }, [store.entry.templateId, location.state?.template, template])

  useEffect(() => {
    setShowSidebar(false)

    return () => {
      setShowSidebar(true)
    }
  }, [setShowSidebar])

  useEffect(() => {
    if (!store.errors.length) {
      setErrorsOpen(false)
      store.resetError()
    }
  }, [store.errors.length])

  useEffect(() => {
    if (mode === 'details') {
      showModal('journeyBuilderLaunchModal')
    }
  }, [mode])

  useEffect(() => {
    if (
      store.entry.status &&
      ((store.entry.status === JourneyStatus.DRAFT && mode === 'details') ||
        (store.entry.status !== JourneyStatus.DRAFT && mode === 'builder'))
    ) {
      goTo('journeys', { appId })
    }
  }, [store.entry.status])

  return (
    <>
      <div
        className={cn('journey-builder position-relative', {
          'journey-builder--overlay': !!sidebarProps?.isOpen
        })}>
        <JourneyBuilderStatus
          exitRoute="journeys"
          name={store.entry.name}
          status={store.entry.status}
        />
        <Markers />
        <ReactFlow
          panOnScroll
          nodesDraggable={false}
          nodesConnectable={false}
          snapToGrid
          // fitView
          edges={store.edges}
          nodes={store.nodes}
          // onNodesChange={() => {}}
          // onEdgesChange={() => {}}
          // onConnect={() => {}}
          onNodeClick={handleNodeClick}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}>
          <JourneyBuilderControls />
        </ReactFlow>
        <Button
          color=""
          onClick={showSetupModal}
          className="journey-builder__open-setup bg-white fw-medium px-4 btn--hover btn-outline-body">
          View Set up
        </Button>
        <div className="journey-builder__btns d-flex">
          <Button
            color=""
            onClick={() => goTo('journeys', { appId })}
            className="bg-white px-4 btn--hover btn-outline-body">
            {mode === 'details' ? 'Return to Journeys' : 'Finish Later'}
          </Button>
          {isDemoAdmin ? (
            <Button
              id="launch-journey-btn"
              color="body"
              disabled={!store.journeyIsInitialized}
              onClick={requestSendApproval}
              className="ms-2 btn--hover d-flex align-items-center">
              Request to Send
            </Button>
          ) : (
            <Button
              id="launch-journey-btn"
              color="body"
              disabled={!store.journeyIsInitialized}
              onClick={onLaunchClick}
              className="ms-2 btn--hover d-flex align-items-center">
              Go to Review
            </Button>
          )}
          <BuilderErrors
            targetId="launch-journey-btn"
            messages={store.errors}
            close={() => setErrorsOpen(false)}
            isOpen={errorsOpen}
          />
        </div>
        <JourneyBuilderSidebar
          disabled={mode === 'details'}
          isOpen={!!sidebarProps?.isOpen}
          type={sidebarProps?.type}
          journeyId={store.entry.blockID}
          data={
            sidebarProps
              ? { ...sidebarProps.data, openPanel: openFromPanel }
              : undefined
          }
          save={() => sidebarProps?.data.block.save(appId, store.entry.blockID)}
          removeBlock={() => {
            if (sidebarProps?.data.block) {
              store.removeBlock(sidebarProps.data.block)
            }
          }}
          close={onSidebarClose}
          cancelCallback={() => sidebarProps?.data.block.restore()}
          redirectTargets={
            getChildrenByType(
              [
                JourneyBlockType.MESSAGE,
                JourneyBlockType.ADDTOSEGMENT,
                JourneyBlockType.DELAY
              ],
              store.entry
            ) || []
          }
        />

        <SetupModal
          isSaving={isBusy}
          saveJourney={saveJourney}
          template={template}
          disabled={mode === 'details'}
        />
        <LaunchModal
          details={mode === 'details'}
          closeCallback={store.entry.resetError}
          name={template?.name || ''}
          icon={template?.smallImage || ''}
          startAt={store.entry.startAt.value}
          endAt={store.entry.endAtActive ? store.entry.endAt.value : null}
          timeZoneName={store.entry.timeZoneName.value}
          segments={store.entry.targeting.segments}
          geofences={store.entry.targeting.geofences}
          beacons={store.entry.targeting.beacons}
          launchJourney={launchJourney}
          isLaunching={isBusy}
          status={store.entry.status}
          messages={
            getChildrenByType([JourneyBlockType.MESSAGE], store.entry) || []
          }
        />

        {isModalOpen('journeyBuilderLaunchModal') && (
          <JourneyBottomBar
            isLoading={isBusy}
            disabled={!journeyId || !store.isValid || isBusy}
            launch={launchJourney}
            save={async () => {
              setIsBusy(true)
              await store.entry.save(appId)
              setIsBusy(false)
            }}
            startBlock={store.entry}
          />
        )}
      </div>
      <RequestNotificationSendingApproval notificationType="journey" />
    </>
  )
}

export default withStore(JourneyBuilder)
