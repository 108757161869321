import React from 'react'
import Chart from 'react-apexcharts'
import cloneDeep from 'lodash/cloneDeep'
import DEFAULT_OPTIONS from './chartOptions'
import { IRadialBarChartData } from '~/pages/Campaign/CampaignReports/Model/Report.interface'

const ApexRadialBarChart = ({
  chartData
}: {
  chartData: IRadialBarChartData
}): React.ReactElement | null => {
  const parseOptions = () => {
    const options = cloneDeep(DEFAULT_OPTIONS)
    if (options?.labels) {
      options.labels.push(chartData.label)
    }

    return options
  }

  return (
    <Chart
      options={parseOptions()}
      series={[chartData.value || 0]}
      type="radialBar"
      height={350}
    />
  )
}

export default ApexRadialBarChart
