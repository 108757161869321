import cn from 'classnames'
import { Navbar as RSNavbar } from 'reactstrap'
import NavbarDropdowns from './NavbarDropdowns'
import NavbarToggle from './NavbarToggle'
import { navbar } from './NavBar.scss'

const Navbar = () => {
  return (
    <RSNavbar
      expand
      container={false}
      className={cn('navbar-container', 'navbar-theme', navbar)}>
      <NavbarToggle />
      <div id="navbar-builder-status-portal" />
      <NavbarDropdowns />
    </RSNavbar>
  )
}

export default Navbar
