import cn from 'classnames'
import { withStore, useStore } from '~/dataStore'

const NavbarToggle = () => {
  const {
    ui: { toggleSidebar }
  } = useStore()

  return (
    <button
      type="button"
      className={cn('sidebar-toggle', 'd-flex', 'me-2', 'btn--transparent')}
      onClick={toggleSidebar}>
      <i className={cn('hamburger', 'align-self-center')} />
    </button>
  )
}

export default withStore(NavbarToggle)
