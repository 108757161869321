import React, { useRef } from 'react'
import cn from 'classnames'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
  UncontrolledTooltip
} from 'reactstrap'
import { uniqueId } from 'lodash'
import { handleLongName } from '~/utils/string'

import Checkbox from '../Checkbox'

interface IProps {
  title?: string | React.ReactElement
  resetOptionName?: string
  options: Array<OptionType>
  selectedOptions: Array<string>
  onSelect: (options: Array<string>, value?: string) => void
  className?: string
  bottomChildren?: React.ReactNode
  buttonDropdownStyle?: React.CSSProperties
  right?: boolean
}

type OptionType = {
  name: string | React.ReactNode
  tooltip?: string
  value?: string
  disabled?: boolean
}

const DropdownItemLabel = ({
  name,
  tooltip
}: {
  name: string | React.ReactNode
  tooltip?: string
}) => {
  const id = useRef(uniqueId('dropdown-item'))
  return (
    <>
      <div id={id.current}>{handleLongName(name, 20)}</div>
      {(tooltip || (typeof name === 'string' && name.length >= 20)) && (
        <UncontrolledTooltip target={id.current}>
          {tooltip || name}
        </UncontrolledTooltip>
      )}
    </>
  )
}

const MultiSelect = ({
  title,
  resetOptionName,
  options,
  selectedOptions,
  onSelect,
  className,
  bottomChildren,
  buttonDropdownStyle,
  right = false
}: IProps): React.ReactElement => {
  const dropdownStyle = {
    maxWidth: '36ch',
    minWidth: '250px',
    maxHeight: 325,
    overflowY: 'auto',
    paddingTop: 15
  }

  const onClick = (value: string) => {
    return (checked: boolean) => {
      if (checked) {
        onSelect([...selectedOptions, value], value)
      } else {
        onSelect(
          selectedOptions.filter((option) => option !== value),
          value
        )
      }
    }
  }

  return (
    <UncontrolledButtonDropdown style={buttonDropdownStyle}>
      <DropdownToggle
        caret
        color=""
        outline
        className={cn(className, `border rounded border-input`)}>
        {title}{' '}
      </DropdownToggle>
      <DropdownMenu style={dropdownStyle} end={right}>
        {resetOptionName && (
          <DropdownItem
            className="btn-no-outline"
            style={{ color: '#28518C' }}
            onClick={() => onSelect([])}>
            {resetOptionName}
          </DropdownItem>
        )}
        {options.map(
          ({ name, tooltip, value, disabled }: OptionType, index: number) => {
            const key = `${value}-${index}`
            if (value) {
              return (
                <DropdownItem
                  key={key}
                  toggle={false}
                  tag="div"
                  disabled={disabled}
                  style={{ color: '#28518C' }}
                  className="p-0">
                  <Checkbox
                    wrapperClass="px-3 py-2"
                    readOnly
                    className="d-flex"
                    disabled={disabled}
                    checked={selectedOptions.includes(value)}
                    onChange={onClick(value)}
                    label={<DropdownItemLabel tooltip={tooltip} name={name} />}
                  />
                </DropdownItem>
              )
            }
            return <DropdownItemLabel key={key} tooltip={tooltip} name={name} />
          }
        )}
        {bottomChildren}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}

export default MultiSelect
