import React, { useState, useEffect, useRef } from 'react'
import { Progress, UncontrolledTooltip } from 'reactstrap'
import { XCircle } from 'react-feather'
import { useParams } from 'react-router-dom'
import ReactDOM from 'react-dom'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'
import { CampaignStatus } from '~/dataStore/Campaign/Campaign.interface'
import { useStore, withStore } from '~/dataStore'
import StatusPill from '~/components/Filters/StatusPill'

import './BuilderStatus.scss'

interface IProps {
  loading?: boolean | null
  name: string
  status?: JourneyStatus | CampaignStatus
  autoSave?: boolean
  exitRoute: 'journeys' | 'campaignList'
}

const BuilderStatus = ({
  loading,
  name,
  status,
  autoSave,
  exitRoute
}: IProps) => {
  const [saveProgress, setSaveProgress] = useState(0)
  const { goTo } = useStore()

  // old rails views
  let appId
  try {
    const params = useParams()
    appId = params.appId
  } catch (error) {}

  const progressInterval = useRef<number | null>(null)

  useEffect(() => {
    if (!autoSave) return
    if (loading) {
      setSaveProgress(30)
      progressInterval.current = setInterval(() => {
        setSaveProgress((prev) => {
          if (prev + 20 >= 100) {
            return prev
          }
          return prev + 20
        })
      }, 400)
    } else if (loading !== null) {
      clearInterval(progressInterval.current)
      setSaveProgress(100)
      setTimeout(() => {
        setSaveProgress(0)
      }, 2000)
    }
  }, [loading])

  if (!name) return null

  return (
    <div
      className={`navbar-builder-status ${
        loading ? 'navbar-builder-status--in-progress' : ' '
      }`}>
      {status && (
        <StatusPill
          status={status || 'draft'}
          className="navbar-builder-status__status-pill">
          <>
            {autoSave && (
              <Progress
                value={saveProgress}
                color="info"
                animated={false}
                className="navbar-builder-status__save-progress"
              />
            )}
            <span className="navbar-builder-status__label">
              {saveProgress === 100 && 'Saved'}
              {!loading && saveProgress === 0 ? status : ''}
              {loading ? 'Saving...' : ''}
            </span>
          </>
        </StatusPill>
      )}
      <span className="navbar-builder-status__name mx-3">{name}</span>
      <XCircle
        color="white"
        className="cursor-pointer navbar-builder-status__exit"
        id="exit-builder"
        onClick={() => goTo(exitRoute, { appId })}
      />
      <UncontrolledTooltip target="exit-builder" placement="right">
        {autoSave ? 'Finish later' : 'Exit'}
      </UncontrolledTooltip>
    </div>
  )
}

const BuilderStatusPortal = (props: IProps) =>
  ReactDOM.createPortal(
    <BuilderStatus {...props} />,
    document.getElementById('navbar-builder-status-portal')
  )

export default withStore(BuilderStatusPortal)
