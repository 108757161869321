import React, { useState, useRef } from 'react'
import cn from 'classnames'
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown,
  UncontrolledTooltip
} from 'reactstrap'
import { uniqueId } from 'lodash'

interface IProps {
  title?: string
  options: { name: any; value: any; dataTestId?: string }[]
  selectedOption: any
  onSelect: (value: any) => void
  className?: string
  popupClassName?: string
  wrapperClassName?: string
  disabled?: boolean
  right?: boolean
  id?: string
  onBlur?: () => void
  errorTooltip?: string
  invalid?: boolean
  dataTestId?: string
}

export default function Select({
  title,
  options,
  selectedOption,
  onSelect,
  className,
  wrapperClassName,
  popupClassName,
  disabled,
  id,
  onBlur,
  dataTestId,
  errorTooltip,
  right = false,
  invalid = false
}: IProps): React.ReactElement {
  const uniqueID = useRef(uniqueId('select-control-'))
  const [dropdownOpen, setDropdownOpen] = useState(false)

  function toggle() {
    setDropdownOpen((isOpen) => !isOpen)
  }

  return (
    <ButtonDropdown
      id={id || uniqueID.current}
      isOpen={dropdownOpen}
      toggle={toggle}
      onBlur={onBlur}
      className={cn(
        { 'disabled-field': disabled },
        wrapperClassName,
        'position-relative'
      )}>
      <DropdownToggle
        data-testid={dataTestId}
        disabled={disabled}
        caret
        color="primary"
        className={cn(
          'bg-white text-body border rounded',
          className,
          { 'border-input': !invalid },
          { 'error-input': invalid }
        )}>
        {options.find((o) => o.value.toString() === selectedOption?.toString())
          ?.name ||
          selectedOption ||
          title}
      </DropdownToggle>
      <DropdownMenu
        end={right}
        modifiers={[
          {
            name: 'maxHeight',
            enabled: true,
            phase: 'beforeWrite',
            fn({ state }) {
              return {
                ...state,
                styles: {
                  ...state.styles,
                  popper: {
                    ...state.styles.popper,
                    maxHeight: `200px`,
                    overflowY: `auto`
                  }
                }
              }
            }
          }
        ]}
        className={popupClassName}>
        {options.map(({ name, value, dataTestId }) => (
          <DropdownItem
            key={value || name}
            data-testid={dataTestId}
            onClick={() => {
              setDropdownOpen(false)
              onSelect(value)
            }}
            toggle={false}
            style={{ color: '#28518C' }}
            className="d-flex align-items-center btn-no-outline">
            {name}
          </DropdownItem>
        ))}
      </DropdownMenu>

      {errorTooltip && (
        <UncontrolledTooltip
          className="tooltip-validation-error"
          placement="top-end"
          target={id || uniqueID.current}>
          {errorTooltip}
        </UncontrolledTooltip>
      )}
    </ButtonDropdown>
  )
}
