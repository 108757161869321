import { ID, IGeneralBulkActions, IMetaData } from '~/common.interface'
import { IGroup } from '~/dataStore/Groups/Groups.interface'

export const QUERY_PARAMS_DEFAULTS = {
  sort: 'created_at',
  order: 'desc',
  groupIds: '',
  searchQuery: '',
  types: ''
}

export interface ISegmentsListQueryParams {
  sort?: string
  order?: string
  groupIds?: string[]
  searchQuery?: string
  page?: number
  // eslint-disable-next-line camelcase
  per_page?: number
}

export interface ISegmentDTO {
  id: ID
  actions: {
    edit: boolean
    export: boolean
    duplicate: boolean
    delete: boolean
    view: boolean
  }
  name: string
  createdAt: string
  cachedUsersCount: number
  usersDiff: number
  groupsIds: string[]
  groups: Array<{
    joinType: string
    rules: Array<{
      timeFrame: string
      timeDirection: string
      matchValueType: string
      matchType: string
      matchValue: string
      matchEndValue: string
      eventResourceId: string
      eventResourceType: string
      inAppEventName: string
      type: string
      updatedAt: string
      createdAt: string
      locations: unknown | null
    }>
  }>
}

export interface ISegmentsDTO {
  data: ISegmentDTO[]
  bulkActions: IGeneralBulkActions
  metadata: IMetaData
}

export interface ISegmentListItem extends Omit<ISegmentDTO, 'groups'> {
  groups: IGroup[]
  batchActionUrls: IGeneralBulkActions
}

export interface ISegmentUserRQ {
  data: ISegmentUser[]
  metadata: IMetaData
}

export interface ISegmentUser {
  alias: string
  createdAt: string
  currentCity: string
  id: string
  lastName: string
  lastSessionAt: string
  viewProfile: string
}
