import { action, makeObservable, observable, override } from 'mobx'
import moment from 'moment-timezone'
import { TARGETS, DELIVERY } from '~/pages/CampaignBuilder/Email/consts'
import { getLeafs, sumDelays } from '~/pages/Journeys/Journey.service'
import { JourneyStatus } from '~/pages/Journeys/Journeys.interface'
import {
  saveJourney,
  updateJourney
} from '~/pages/Journeys/Connector/Journeys.connector'
import { DEFAULT_TIMEZONE } from '~/dataStore/emailBuilder/EmailBuilder.interface'
import RegisteredField from '~/dataStore/emailBuilder/RegisteredField.model'
import { CustomErrors, ID } from '~/common.interface'
import {
  formatToTimeZone,
  formatToUTC,
  getUTC
} from '~/helpers/deliverTimeWindows'
import {
  IBlock,
  JourneyBlockType,
  JourneyDTO
} from '../Store/JourneyBuilder.interface'
import Block from './Block.model'
import Targeting from '~/dataStore/emailBuilder/Targeting/Targeting'
import Target from '~/dataStore/emailBuilder/Targeting/Target'

type StartBlockDTO = Pick<
  JourneyDTO,
  | 'name'
  | 'description'
  | 'templateId'
  | 'startAt'
  | 'endAt'
  | 'delivery'
  | 'segments'
  | 'beacons'
  | 'beaconEvents'
  | 'geofences'
  | 'geofenceEvents'
  | 'geofenceDwellingTimes'
  | 'timeZoneName'
  | 'timeZoneOffset'
>

export default class Start extends Block<StartBlockDTO> {
  //! blockID === Journey ID

  //* Journey Fields
  public name = ''

  public description: string | undefined = undefined

  public status?: JourneyStatus

  #templateId: ID | undefined = undefined

  //* Start Block Fields

  public targeting = new Targeting()

  public startAt = new RegisteredField<Date | null>(null)

  public endAt = new RegisteredField<Date | null>(null)

  public endAtActive = false

  public timeZoneName = new RegisteredField(DEFAULT_TIMEZONE)

  public timeZoneOffset: string = moment.tz(DEFAULT_TIMEZONE).format('Z')

  public delivery = new RegisteredField<
    (typeof DELIVERY)[keyof typeof DELIVERY] | null
  >(null)

  constructor(private appId: ID, templateId?: ID) {
    super(JourneyBlockType.START, null)
    makeObservable<
      Start,
      | 'fillBlockPayload'
      | 'resetError'
      | 'validateStartAt'
      | 'validateEndAt'
      | 'validateTargeting'
      | 'validateDelivery'
    >(this, {
      name: observable,
      description: observable,
      status: observable,
      targeting: observable,
      startAt: observable,
      endAtActive: observable,
      timeZoneName: observable,
      timeZoneOffset: observable,
      delivery: observable,
      fillBlock: override,
      fillBlockPayload: action.bound,
      resetError: override,
      setName: action.bound,
      setEndActive: action.bound,
      setDescription: action.bound,
      setTimezone: action.bound,
      validateStartAt: action.bound,
      validateEndAt: action.bound,
      validateTargeting: action.bound,
      validateDelivery: action.bound
    })

    this.templateId = templateId

    this.selectTarget = this.selectTarget.bind(this)
    this.setStartAt = this.setStartAt.bind(this)
    this.setEndAt = this.setEndAt.bind(this)
    this.setDelivery = this.setDelivery.bind(this)
    this.validateBlock = this.validateBlock.bind(this)
    this.validateDates = this.validateDates.bind(this)
  }

  get templateId(): ID | undefined {
    return this.#templateId
  }

  set templateId(templateId: ID | undefined) {
    this.#templateId =
      templateId === 'blankJourneyTemplate' ? undefined : templateId
  }

  get filled(): boolean {
    return (
      this.targeting.segments.selectedCount > 0 ||
      this.targeting.beacons.selectedCount > 0 ||
      this.targeting.geofences.selectedCount > 0
    )
  }

  public restore(): void {
    if (this.currentBlockState) {
      this.targeting.reset(TARGETS.SEGMENTS)
      this.targeting.segments.clearSelected()
      this.targeting.reset(TARGETS.BEACONS)
      this.targeting.beacons.clearSelected()
      this.targeting.reset(TARGETS.GEOFENCES)
      this.targeting.geofences.clearSelected()
      super.restore()
    }
  }

  public getPayload(): JourneyDTO {
    return {
      id: this.blockID,
      ...this.getBlockPayload(),
      childBlocks: this.children.map((child) => child.getPayload())
    }
  }

  public getBlockPayload(): StartBlockDTO {
    const {
      segmentIds,
      beaconIds,
      beaconEvents,
      geofenceIds,
      geofenceEvents,
      geofenceDwellingTimes
    } = this.targeting.getPayload()

    return {
      name: this.name,
      description: this.description,
      templateId: this.templateId,
      startAt: this.startAt.value
        ? formatToUTC(this.startAt.value, this.timeZoneName.value).format(
            'YYYY-MM-DD[T]HH:mm:ss'
          )
        : null,
      endAt:
        this.endAtActive && this.endAt.value
          ? formatToUTC(this.endAt.value, this.timeZoneName.value).format(
              'YYYY-MM-DD[T]HH:mm:ss'
            )
          : null,
      delivery: this.targeting.isSegmentsOnlyActiveTarget
        ? this.delivery.value
        : null,
      segments: segmentIds,
      beacons: beaconIds,
      beaconEvents,
      geofences: geofenceIds,
      geofenceEvents,
      geofenceDwellingTimes,
      timeZoneName: this.timeZoneName.value,
      timeZoneOffset: this.timeZoneOffset
    }
  }

  public fillBlock(data: JourneyDTO): void {
    super.fillBlock(data)
    this.status = data.status
  }

  protected fillBlockPayload(data: StartBlockDTO): void {
    this.name = data.name || ''
    this.description = data.description || ''
    this.timeZoneName.setValue(data.timeZoneName || this.timeZoneName.value)
    this.timeZoneOffset = moment.tz(this.timeZoneName.value).format('Z')
    this.templateId = data.templateId

    if (data.startAt) {
      this.startAt.fillField({
        value: formatToTimeZone(data.startAt, this.timeZoneName.value)
      })
    }

    if (data.endAt) {
      this.endAtActive = true
      this.endAt.fillField({
        value: formatToTimeZone(data.endAt, this.timeZoneName.value)
      })
    }
    this.targeting.fillStore({
      segmentIds: data.segments || [],
      beaconIds: data.beacons || [],
      beaconEvents: data.beaconEvents || {},
      geofenceIds: data.geofences || [],
      geofenceEvents: data.geofenceEvents || {},
      geofenceDwellingTimes: data.geofenceDwellingTimes || {}
    })

    this.delivery.fillField({ value: data.delivery })

    this.setReady()
  }

  public validateBlock(): boolean {
    this.validateTargeting()
    this.validateDelivery()

    if (!this.isValid) {
      this.launchErrors = ['You must set starting point']
    }

    return this.isValid
  }

  private validateTargeting(): void {
    this.targeting.validateStep()

    if (!this.targeting.isStepValid) {
      this.isValid = false
      this.errors = this.errors.concat(
        this.targeting.registeredFields.flatMap((field) =>
          field.errors.map((error) => error.message)
        )
      )
    }
  }

  private validateDelivery(): void {
    if (this.targeting.isSegmentsOnlyActiveTarget && !this.delivery.value) {
      this.isValid = false
      this.delivery.isValid = false
      this.errors.push('You must choose a delivery method')
    }
  }

  public validateDates(): void {
    this.validateStartAt()
    this.validateEndAt()
    this.validateTimeZone()
  }

  private validateTimeZone(): void {
    if (!this.timeZoneName.value) {
      this.isValid = false
      this.timeZoneName.isValid = false
      this.errors.push('You must set a time zone')
    }
  }

  private validateStartAt(): void {
    if (!this.startAt.value) {
      this.isValid = false
      this.startAt.isValid = false
      this.errors.push('You must set a start date')
    } else if (
      formatToUTC(this.startAt.value, this.timeZoneName.value).isBefore(
        getUTC()
      )
    ) {
      this.isValid = false
      this.startAt.isValid = false
      this.errors.push("The start of the journey can't be in the past")
    }
  }

  private validateEndAt(): void {
    if (!this.endAtActive || !this.startAt.value) {
      return
    }

    if (!this.endAt.value) {
      this.isValid = false
      this.endAt.isValid = false
      this.errors.push('You need to choose an End date or switch it off')
      return
    }

    const delay = this.getDelayInMinutes() + 180
    if (
      moment(this.endAt.value).isBefore(
        moment(this.startAt.value).add(delay, 'm')
      )
    ) {
      this.isValid = false
      this.endAt.isValid = false
      this.errors.push('Please choose valid end date')
    }
  }

  public getDelayInMinutes(): number {
    const leafs: IBlock[] = []
    getLeafs(this, leafs)

    let delay = 0

    leafs.forEach((block) => {
      const temp = sumDelays(block, 0)
      if (delay < temp) {
        delay = temp
      }
    })

    return delay
  }

  public resetError(): void {
    super.resetError()
    this.delivery.resetError()
    this.startAt.resetError()
    this.endAt.resetError()
    this.timeZoneName.resetError()
  }

  public setName(value: string): void {
    this.name = value
  }

  public setDescription(value?: string): void {
    this.description = value
  }

  public setDelivery(
    value: (typeof DELIVERY)[keyof typeof DELIVERY] | null
  ): void {
    this.delivery.setValue(value)
    this.resetError()
    this.validateDelivery()
  }

  // Used to clear error in Entry panel
  public selectTarget(selected: boolean): void {
    if (selected) {
      this.resetError()
      this.validateTargeting()
    }
  }

  public setStartAt(value: Date): void {
    this.startAt.setValue(value)

    if (
      value &&
      formatToUTC(value, this.timeZoneName.value).isAfter(getUTC())
    ) {
      this.resetError()
      this.validateStartAt()
    }
  }

  public setEndAt(value: Date): void {
    this.endAt.setValue(value)

    if (
      value &&
      formatToUTC(value, this.timeZoneName.value).isAfter(getUTC())
    ) {
      this.resetError()
      this.validateEndAt()
    }
  }

  public setEndActive(value: boolean): void {
    this.endAtActive = value

    if (value === false) {
      this.resetError()
    }
  }

  public setTimezone({ timeZoneName }: { timeZoneName?: string }): void {
    if (!timeZoneName) {
      this.timeZoneName.setValue('')
      this.timeZoneOffset = moment.tz('').format('Z')
    }

    if (timeZoneName) {
      this.timeZoneName.setValue(timeZoneName)
      this.timeZoneOffset = moment.tz(timeZoneName).format('Z')
      this.timeZoneName.resetError()

      if (
        this.startAt.value &&
        formatToUTC(this.startAt.value, this.timeZoneName.value).isAfter(
          getUTC()
        )
      ) {
        this.resetError()
        this.validateStartAt()
      }
    }
  }

  public async save(appID: ID): Promise<ID> {
    this.validateBlock()

    if (!this.isValid) {
      throw new Error(CustomErrors.INVALID)
    }
    return this.saveJourney(appID)
  }

  public async saveJourney(appID: ID): Promise<ID> {
    const payload: JourneyDTO = this.getPayload()
    let response: Required<JourneyDTO>

    if (this.blockID) {
      response = await updateJourney(appID, this.blockID, payload)
    } else {
      response = await saveJourney(appID, payload)
      this.fillBlock(response)
    }

    return response.id
  }

  protected getNodeData(): {
    label: string
    multiLabel?: { header: string; value: string }[]
    subLabel?: string
  } {
    const segments = this.getTargetingLabels(this.targeting.segments)
    const beacons = this.getTargetingLabels(this.targeting.beacons)
    const geofences = this.getTargetingLabels(this.targeting.geofences)

    const multiLabel = []
    if (segments) {
      multiLabel.push({ header: 'Segments', value: segments })
    }
    if (beacons) {
      multiLabel.push({ header: 'Beacons', value: beacons })
    }
    if (geofences) {
      multiLabel.push({ header: 'Geofences', value: geofences })
    }

    return {
      label: 'Segment Name',
      multiLabel
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getTargetingLabels(targeting: Target<any>): string {
    return targeting.selectedList.map((target) => target.name).join(', ')
  }
}
