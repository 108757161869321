import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap'
import { MoreHorizontal } from 'react-feather'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { uniqueId } from 'lodash'
import Submenu from './Submenu'

interface IProps {
  dropdownToggle?: React.ReactElement
  visible?: boolean
  selectedItems?: any
  options: {
    name: string | React.ReactElement | ((...args: any) => void)
    icon?: any
    action?: (selected?: any[]) => void
    isCustom?: boolean
    hidden?: boolean
  }[]
  className?: string
  dropdownToggleClassname?: string
  wrapperClassname?: string
  right?: boolean
  disabled?: boolean
  opacityAnimation?: boolean
  container?: string
  id?: string
  persist?: boolean
  withoutModifiers?: boolean
  toggleAsButton?: boolean
  onClose?: () => void
}

function CustomDropdownItem({ icon, name, toggle }) {
  return (
    <div>
      {icon && (
        <FontAwesomeIcon icon={icon} size="sm" className="me-2" fixedWidth />
      )}
      {typeof name === 'function' ? name(toggle.bind(null, false)) : name}
    </div>
  )
}

function ClassicDropdownItem({
  action,
  icon,
  name,
  selectedItems,
  options,
  toggleParent
}) {
  return (
    <>
      {options?.length > 0 ? (
        <Submenu
          options={options}
          name={name}
          action={action}
          toggleParent={toggleParent}
        />
      ) : (
        <DropdownItem
          onClick={() => {
            if (action) {
              action(selectedItems)
            }
          }}
          className="btn-no-outline text-dark">
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              size="sm"
              className="me-2"
              fixedWidth
            />
          )}
          {name}
          {options && (
            <FontAwesomeIcon
              icon="chevron-right"
              size="sm"
              className="ms-auto"
              fixedWidth
            />
          )}
        </DropdownItem>
      )}
    </>
  )
}

export default function CustomDropdown({
  visible = true,
  selectedItems,
  options,
  className,
  dropdownToggleClassname,
  wrapperClassname,
  right = false,
  dropdownToggle,
  disabled,
  opacityAnimation,
  container,
  id,
  persist,
  withoutModifiers,
  toggleAsButton = false,
  onClose
}: IProps): React.ReactElement | null {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = (newState?: boolean) => {
    setDropdownOpen((prevState) => {
      return typeof newState !== 'undefined' ? newState : !prevState
    })
  }

  const extendedProps: { id?: string } = {}
  if (id) {
    extendedProps.id = uniqueId(id)
  }

  useEffect(() => {
    if (onClose && !dropdownOpen) {
      onClose()
    }
  }, [onClose, dropdownOpen])

  if (!visible) return null

  return (
    <UncontrolledButtonDropdown
      isOpen={dropdownOpen}
      toggle={() => toggle()}
      className={wrapperClassname}>
      {dropdownToggle ? (
        <DropdownToggle
          color=""
          style={{ minHeight: '40px' }}
          className={cn(
            'rounded',
            { 'p-0 shadow-none rounded': !toggleAsButton },
            { show: dropdownOpen },
            { 'bg-white btn--hover btn-outline-body py-0': toggleAsButton },
            dropdownToggleClassname
          )}
          {...extendedProps}
          disabled={disabled}>
          {dropdownToggle}
        </DropdownToggle>
      ) : (
        <DropdownToggle
          disabled={disabled}
          color=""
          className={cn(
            className,
            'border rounded border-input px-2 py-0 me-2'
          )}
          style={{ minHeight: '40px' }}>
          <MoreHorizontal />
        </DropdownToggle>
      )}
      <DropdownMenu
        modifiers={[
          {
            name: 'maxHeight',
            enabled: !withoutModifiers,
            phase: 'beforeWrite',
            fn({ state }) {
              return {
                ...state,
                styles: {
                  ...state.styles,
                  popper: {
                    ...state.styles.popper
                  }
                }
              }
            }
          }
        ]}
        persist={persist}
        style={{ top: '40px' }}
        className={cn('p-0', { 'opacity-animation': opacityAnimation })}
        end={right}
        container={container}>
        {dropdownOpen &&
          options
            .filter((o) => !o.hidden)
            .map(({ name, icon, action, isCustom = false, options }, index) => {
              if (isCustom) {
                return (
                  <CustomDropdownItem
                    key={index}
                    name={name}
                    action={action}
                    icon={icon}
                    toggle={toggle}
                  />
                )
              }
              return (
                <ClassicDropdownItem
                  key={index}
                  name={name}
                  action={action}
                  selectedItems={selectedItems}
                  icon={icon}
                  options={options}
                  toggleParent={toggle}
                  className={className}
                />
              )
            })}
      </DropdownMenu>
    </UncontrolledButtonDropdown>
  )
}
