import React from 'react'
import Chart from 'react-apexcharts'
import DEFAULT_OPTIONS from './chartOptions'

import './ApexAreaChart.scss'
import { IGraphData } from '~/pages/Campaign/CampaignReports/Model/Report.interface'

interface ITooltip {
  series: [][]
  seriesIndex: number
  dataPointIndex: number
  w: unknown
}

interface ICustomTooltip extends ITooltip {
  customLabel: (label: string | number) => void
}

interface IProps {
  graphData: IGraphData[]
  height?: number
  tooltipCustomLabel: (label: string | number) => void
}

const getCustomTooltip = ({
  series,
  seriesIndex,
  dataPointIndex,
  w,
  customLabel
}: ICustomTooltip) => {
  return `<div class="apex-area-chart-tooltip">
      <span class="apex-area-chart-tooltip__label">${
        customLabel
          ? customLabel(w.globals.categoryLabels[dataPointIndex])
          : w.globals.categoryLabels[dataPointIndex]
      }</span>
      <span class="apex-area-chart-tooltip__value">
        ${series[seriesIndex][dataPointIndex]}
      </span>
    </div>`
}

const ApexAreaChart = ({
  graphData,
  tooltipCustomLabel,
  height = 160
}: IProps): React.ReactElement => {
  const getChartOptions = () => {
    const options = DEFAULT_OPTIONS

    if (options?.tooltip) {
      options.tooltip.custom = (props: ITooltip) =>
        getCustomTooltip({
          ...props,
          ...(tooltipCustomLabel && {
            customLabel: tooltipCustomLabel
          })
        })
    }

    return options
  }

  return (
    <Chart
      type="area"
      series={[{ data: graphData }]}
      options={getChartOptions()}
      height={height}
    />
  )
}

export default ApexAreaChart
