import React from 'react'
import { useParams } from 'react-router-dom'
import useJourneyStore from '../../Journey.context'
import { useStore, withStore } from '~/dataStore'
import Modal from '~/components/modals/Modal/Modal'
import Setup from './Setup'
import { JourneyTemplate } from '../../Store/JourneyBuilder.interface'

import './SetupModal.scss'

const SetupModal = ({
  saveJourney,
  template,
  isSaving,
  disabled
}: {
  saveJourney: () => void
  template?: JourneyTemplate
  isSaving: boolean
  disabled: boolean
}) => {
  const {
    entry: {
      name,
      description,
      setDescription,
      setName,
      blockID,
      restore,
      clearCurrentState
    }
  } = useJourneyStore()
  const { goTo } = useStore()
  const { appId } = useParams<{ appId: string }>()
  const goToTemplates = () => goTo('journeysNew', { appId })

  return (
    <Modal
      backdrop={!blockID ? 'static' : true}
      container="body"
      id="journeyBuilderSetupModal"
      renderHeader={() => 'Set up Journey'}
      closeCallback={() => {
        if (blockID) {
          restore()
          clearCurrentState()
        } else {
          goToTemplates()
        }
      }}
      className="journeys-setup-modal"
      render={(close: () => void) => (
        <Setup
          disabled={disabled}
          close={close}
          description={description}
          setDescription={setDescription}
          name={name}
          setName={setName}
          journeyId={blockID}
          saveJourney={async () => {
            await saveJourney()
            clearCurrentState()
            close()
          }}
          goToTemplates={goToTemplates}
          template={template}
          isSaving={isSaving}
        />
      )}
    />
  )
}

export default withStore(SetupModal)
