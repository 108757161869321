import { action, computed, observable, makeObservable, runInAction } from 'mobx'
import { fetchSegmentsReach, fetchSegments } from '~/api/segments'
import { TARGETS } from '~/pages/CampaignBuilder/Email/consts'
import { getGroups } from '~/api/groups'
import { ISegmentDTO } from '~/pages/Segment/SegmentsList/SegmentsList.interface'
import Target from './Target'
import { ISegmentTarget } from '~/dataStore/emailBuilder/EmailBuilder.interface'

export default class Segments extends Target<ISegmentDTO> {
  targetName = TARGETS.SEGMENTS

  reachRange: { min: number; max: number } | null = null

  timeoutId: NodeJS.Timeout | undefined

  public getAppId: undefined | (() => string | undefined) = undefined

  constructor() {
    super()

    makeObservable(this, {
      reachRange: observable,
      fetchSegments: action.bound,
      fillSegments: action.bound,
      fetchReach: action,
      selectSegment: action.bound,
      fetchGroups: action.bound,
      segmentsReach: computed
    })
  }

  async fetchSegments(appId: string, reset = false): Promise<void> {
    const currentSegments = reset ? [] : this.collection
    this.page = reset ? 1 : this.page + 1
    const {
      data: segments,
      metadata: { totalPages, dataCount }
    } = await fetchSegments(appId, {
      page: this.page,
      searchQuery: this.searchQuery,
      groupIds: this.selectedGroups
    })
    runInAction(() => {
      this.hasMore = this.page < totalPages
      this.collection = currentSegments.concat(
        segments.map(
          (segment) =>
            this.selected.get(segment.id) || {
              ...segment,
              totalUsers: segment.cachedUsersCount
            }
        )
      )

      if (!this.searchQuery && !this.selectedGroups.length) {
        this.totalCount = dataCount
      }
    })
  }

  fillSegments(segmentIds: string[] | undefined): boolean {
    segmentIds?.forEach((segmentId) => {
      const selectedSegment = this.collection.find(
        (segment) => segmentId === segment.id
      )
      if (selectedSegment) {
        this.selected.set(segmentId, selectedSegment)
      }
    })
    if (segmentIds?.length) {
      this.setActive(true)
      this.fetchReach()
    }

    return this.isActive
  }

  selectSegment(segment: ISegmentTarget, isSelected: boolean): void {
    if (!isSelected) {
      this.selected.delete(segment.id)
    } else {
      this.selected.set(segment.id, segment)
    }

    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }

    this.timeoutId = setTimeout(() => this.fetchReach(), 200)
  }

  async fetchReach(): Promise<void> {
    this.reachRange = null
    const appId = this.getAppId?.()

    if (!this.selected.size || !appId) {
      this.reachRange = { min: 0, max: 0 }
      return
    }

    try {
      const response = await fetchSegmentsReach(
        appId,
        Array.from(this.selected.keys())
      )
      runInAction(() => {
        this.reachRange = response
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
      runInAction(() => {
        this.reachRange = { min: 0, max: 0 }
      })
    }
  }

  async fetchGroups(appId: string): Promise<void> {
    const groups = await getGroups(appId, 'segments')
    runInAction(() => {
      this.groups = groups.data.map((g) => ({ ...g, value: g.id }))
    })
  }

  public get segmentsReach(): null | {
    min: number
    max: number
  } {
    if (!this.isActive) return null

    return this.reachRange
  }
}
