/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'

import LearnMoreTopBanner from './components/LearnMoreTopBanner'
import LearnMoreBottomBanner from './components/LearnMoreBottomBanner'
import {
  fetchJourneyTemplates,
  fetchDefaultJourneyTemplates
} from '../Connector/Journeys.connector'
import { useStore, withStore } from '~/dataStore'

import './NewJourney.scss'
import Link from '~/components/Link'
import routes from '~/routes'
import { JourneyTemplate } from '../JourneyBuilder/Store/JourneyBuilder.interface'

import blankJourney from './blankJourney.json'

const NewJourney = (): React.ReactElement => {
  const { app } = useStore()
  const [templates, setTemplates] = useState<JourneyTemplate[]>()

  const [bannerOpened, setBannerOpened] = useState<boolean>(
    !document.cookie
      .split(';')
      .some((item) => item.trim().startsWith('journeysBannerClosed='))
  )
  const closeBanner = () => {
    setBannerOpened(false)
    document.cookie = 'journeysBannerClosed=true;'
  }

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const [global, personal] = await Promise.all([
          fetchDefaultJourneyTemplates(),
          fetchJourneyTemplates(app.currentApp.id)
        ])
        setTemplates([blankJourney, ...global.data, ...personal.data])
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    }

    fetchTemplates()
  }, [])

  return (
    <div className="new-journey">
      <div className="py-5">
        <LearnMoreTopBanner isOpen={bannerOpened} closeBanner={closeBanner} />
      </div>
      <div className="pb-5">
        <p className="new-journey__tiles-header">
          What kind of automation would you like to create?
        </p>
        <Row>
          {templates?.map((template: JourneyTemplate) => (
            <Col xs="6" key={template.id} className="new-journey__box">
              <Link
                route={routes.journeysBuilder}
                params={{ mode: 'builder' }}
                state={{ template }}
                color=""
                className="new-journey__tile card btn--hover text-decoration-none">
                <div className="new-journey__tile-icon-wrapper">
                  <img
                    className="new-journey__tile-icon mw-100"
                    src={template.smallImage}
                    alt={template.name}
                  />
                </div>
                <Row className="h-100">
                  <Col
                    xs="5"
                    xxxl="6"
                    widths={['xs', 'xxxl']}
                    className="d-flex align-items-center justify-content-center pe-0 pe-xxxl-2">
                    <img
                      src={template.largeImage}
                      className="new-journey__tile-media"
                      alt={template.name}
                    />
                  </Col>
                  <Col
                    xs="7"
                    xxxl="6"
                    widths={['xs', 'xxxl']}
                    className="d-flex align-items-center">
                    <div className="pe-4 pe-xxxl-5">
                      <h4 className="new-journey__tile-title fw-medium mb-3">
                        {template.name}
                      </h4>
                      <p className="new-journey__tile-description fw-medium mb-0">
                        {template.description}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Link>
            </Col>
          ))}
        </Row>
      </div>
      <LearnMoreBottomBanner isOpen={!bannerOpened} />
    </div>
  )
}

export default withStore(NewJourney)
